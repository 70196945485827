/**
 * Набор функций для работы со строками
 * Выпил StringUtils.java из GWT
 */
export class StringUtils {
    public static isEmpty = (str: string): boolean => !str?.length;

    public static isNotEmpty = (str: string): boolean => !StringUtils.isEmpty(str);

    public static substring = (str: string, start: number, end: number): string | null => {
        if (!str) {
            return null;
        }

        if (end > str.length) {
            end = str.length;
        }

        if (start > end) {
            return "";
        }

        return str.substring(start, end);
    }

    public static defaultIfEmpty = (value: string, defaultValue: string): string => {
        return StringUtils.isEmpty(value) ? defaultValue : value;
    }


    public static split = (input: string, separator: string): string[] => {
        return input !== null ? input.split(separator) : [];
    }

    public static createNativeArray = <T,>(): T[] => [];

    public static trimToNull = (str: string): string | null => {
        if (str) {
            str = str.trim();
        }

        return str === null || str === "" ? null : str;
    }

    public static isBlank = (str: string): boolean => !str || !str.trim();

    public static eq = (str1: string, str2: string): boolean => str1 === str2;

    public static splitTrim = (str: string, separator: string): string[] => {
        if (!str) {
            return [];
        }

        const res: string[] = [];
        for (let part of str.split(separator)) {
            const trimmedPart = StringUtils.trimToNull(part)!;
            if (!StringUtils.isEmpty(trimmedPart)) {
                res.push(trimmedPart);
            }
        }

        return res;
    }

    public static join = <T,>(values: T[], separator: string): string => {
        if (!values) {
            return "";
        }

        if (!(values instanceof Array)) {
            const arrayOfValues = Array.from(values);
            return arrayOfValues.join(separator);
        }

        return values.join(separator);
    }

    /**
     * Truncates specified string.
     *
     * @param str       - string to be truncated.
     * @param maxLength - maximum string length
     * @return truncated string
     */
    public static truncate = (str: string, maxLength: number): string => {
        if (!str) {
            return "";
        }

        if (str.length <= maxLength) {
            return str;
        }

        const postfix = "...";
        return str.substring(0, maxLength - postfix.length) + postfix;
    }

    /**
     * Checks if the String contains only unicode digits.
     * A decimal point is not a unicode digit and returns false.
     */
    public static isNumeric = (str: string): boolean => /^\d+$/.test(str);

    public static collapseEmptyLines = (s: string): string | null => {
        if (!s) {
            return null;
        }

        // удаляем повторяющиеся переводы строк
        s = s.replace(/\r\n/g, "\n").replace(/\n+/g, "\n");

        // удаляем перевод строки, если он самом вначале
        if (s.length > 0 && s.charAt(0) === "\n") {
            s = s.substring(1);
        }
        // удаляем перевод строки, если он самом конце
        if (s.length > 0 && s.charAt(s.length - 1) === "\n") {
            s = s.substring(0, s.length - 1);
        }

        return s;
    }

    public static isCutDownText = (text: string, linesLimit: number, lineLength: number): boolean => {
        if (!text || linesLimit === -1) {
            return false;
        }

        // хотим показать две линии, но при условии, что после разворота будет ещё как минимум 2
        let linesCount = 0;
        let lineStart = 0;
        for (let i = 0; i < text.length; i++) {
            const char = text.charAt(i);
            if (char === '\n' || i === text.length - 1) {
                let length = i - lineStart;
                let lines = (length + lineLength - 1) / lineLength;
                lineStart = i;
                linesCount += lines;

                if (linesCount >= linesLimit + 2) {
                    // есть что показать! две видимы - третья "подробнее" - после разворота ещё 2 минимум.
                    return true;
                }
            }
        }

        // не больше трёх строчек -> сворачивать не будем
        return false;
    }
}
